import { Navbar, NavDropdown, Nav, Container, Image } from "react-bootstrap";
import imageBig from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Navbars = () => {
  const navigate = useNavigate(null);
  const handleNavigate = (url) => {
    return navigate(url);
  };
  return (
    <>
      <Navbar
        expand="lg"
        className="bg-body-tertiary shadow position-sticky w-100"
      >
        <Container className="d-flex align-items-center">
          <Navbar.Brand href="#home">
            <div className="">
              <h5 className=" d-flex justify-content-center align-items-center gap-2">
                HTP Aidrop
                <Image src={imageBig} className="w-small" />
              </h5>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="focus-ring focus-ring-light border-0"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-flex gap-3">
              <Nav.Link href="#home" onClick={() => handleNavigate("/")}>
                Dashboard
              </Nav.Link>
              <Nav.Link href="#link" onClick={() => handleNavigate("/list")}>
                Wishlist
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navbars;
