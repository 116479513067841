import {
  Container,
  Row,
  Col,
  Toast,
  Carousel,
  ExampleCarouselImage,
  Image,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import Navbars from "../components/Navbars";
import Cards from "../components/Cards";
import { useEffect, useRef, useState } from "react";
import { instance } from "../global variable/instance";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [ads, setAds] = useState([]);
  useEffect(() => {
    tokenValidate();
    getData();
    getAdsData();
  }, []);

  const getAdsData = () => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/api/ads`
      )
      .then((ress) => {
        setAds(ress.data.data);
      })
      .catch((err) => {
        navigate("/login");
      });
  };

  const getData = () => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/api/list-aidrop/`
      )
      .then((ress) => {
        setData(ress.data.data);
      })
      .catch(() => {
        navigate("/login");
      });
  };

  const tokenValidate = () => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/user/permision`
      )
      .catch(() => {
        navigate("/login");
      });
  };

  const handlePostWishlist = (s) => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/garap/create`,
        {
          id_aidrop: s,
        }
      )
      .then((ress) => {
        handleShow();
      })
      .catch((err) => {
        if (err.response.data.errors === "list already exist") {
          setErrors((errors) => [
            ...errors,
            {
              error: true,
              message: err.response.data.errors,
            },
          ]);
        }
      });
  };
  const search = useRef(null);
  const handlesearch = (e) => {
    e.preventDefault();
    if (!search.current.value) {
      instance
        .post(
          `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/list-aidrop/search`
        )
        .then((ress) => {
          setData(ress.data.data);
        })
        .catch(() => {
          navigate("/login");
        });
    } else {
      instance
        .post(
          `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/list-aidrop/search`,
          {
            name_aidrop: search.current.value,
          }
        )
        .then((ress) => {
          setData(ress.data.data);
        })
        .catch(() => {
          navigate("/login");
        });
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Navbars />
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Wishlist Successfuly Added !</Modal.Title>
          </Modal.Header>
          <Modal.Body>Successfuly add to Wishlist</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <section className="py-5">
        <Row className="right">
          {errors.map((item, index) => {
            return (
              <Col xs="12" key={index + 1}>
                <Toast
                  onClose={() => setErrors([])}
                  className="position-fixed z-1 right"
                >
                  <Toast.Header>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">Error !</strong>
                    <small>Now</small>
                  </Toast.Header>
                  <Toast.Body>{item?.message}</Toast.Body>
                </Toast>
              </Col>
            );
          })}
        </Row>
        <Container>
          <Carousel className="rounded-5 shadow border border-2 border-dark-50 mb-5 overflow-hidden custom-carosel">
            {ads.map((item, index) => {
              return (
                <Carousel.Item>
                  <Image src={item?.src} className="w-100" />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Container>
        <Container className="d-flex justify-content-between align-items-center">
          <h4 className="mb-4">List Aidrop</h4>
          <Form onChange={handlesearch}>
            <Form.Group
              className="mb-3 d-flex align-items-center gap-2"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="mt-3">
                <i class="bx bx-search fs-3"></i>
              </Form.Label>
              <Form.Control
                name="search"
                type="text"
                placeholder="search"
                ref={search}
              />
            </Form.Group>
          </Form>
        </Container>
        <Container>
          <Row className="g-4">
            {data.map((item, index) => {
              return (
                <Col md="4" key={index + 1}>
                  <Cards
                    img={item?.img_url}
                    name={item?.name_aidrop}
                    action={item?.task}
                    twitter={item?.twitter}
                    site={item?.url_aidrop}
                    detail_task={item?.refrence_task}
                    onclick={() => handlePostWishlist(item?.id_aidrop)}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
