import { Card, Image } from "react-bootstrap";
import imageBig from "../assets/logo.png";

const Cards = (props) => {
  const redirect = (url) => {
    if (!url) {
      return;
    }
    window.location.href = url;
  };
  return (
    <>
      <Card className="shadow rounded-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center gap-2">
            <div className="w-25 d-flex justify-content-start align-items-center p-2">
              <Image className="w-100" src={props.img} />
            </div>
            <div className="w-75">
              <h5>{props.name}</h5>
              <p>Action : {props.action}</p>
              <div className="d-flex gap-3 reffrence">
                <span
                  className="d-flex align-items-center gap-1"
                  onClick={() => redirect(props.twitter)}
                >
                  <i class="bx bxl-twitter"></i>Twitter
                </span>
                <span
                  className="d-flex align-items-center gap-1"
                  onClick={() => redirect(props.site)}
                >
                  <i class="bx bx-globe"></i>site
                </span>
                <span
                  className="d-flex align-items-center gap-1"
                  onClick={() => redirect(props.detail_task)}
                >
                  <i class="bx bx-task"></i>Details Task
                </span>
              </div>
            </div>
          </div>
          <div className="add-icon-tx1" onClick={props.onclick}>
            <i class="bx bx-plus"></i>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Cards;
