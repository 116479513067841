import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  ListGroup,
  Alert,
} from "react-bootstrap";
import imageBig from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { instance } from "../global variable/instance";
import { useEffect, useRef } from "react";

const Logins = () => {
  const alertComponents = useRef(null);

  const navigate = useNavigate(null);

  useEffect(() => {
    handlechekToken();
  }, []);

  const handleRedirect = (path) => {
    navigate(path);
  };

  const handlechekToken = () => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/user/permision`
      )
      .then((ress) => {
        handleRedirect("/");
      })
      .catch((err) => {
        return (
          (alertComponents.current.className = "fade alert alert-danger show"),
          (alertComponents.current.innerHTML = "Session Expired")
        );
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!e.target.username.value || !e.target.password.value) {
      return (
        (alertComponents.current.className = "fade alert alert-warning show"),
        (alertComponents.current.innerHTML = "Enter email and password")
      );
    }
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/api/user/login`,
        {
          username: e.target.username.value,
          password: e.target.password.value,
        }
      )
      .then((ress) => {
        handleRedirect("/");
      })
      .catch((err) => {
        alertComponents.current.className = "fade alert alert-danger show";
        alertComponents.current.innerHTML = "Username and Password incorect";
      });
  };
  return (
    <>
      <section className="py-5">
        <Container className="d-flex flex-column align-items-center">
          <div>
            <h2 className="mb-5 d-flex justify-content-center align-items-center gap-2">
              HTP Aidrop
              <Image src={imageBig} className="img-logo" />
            </h2>
          </div>
          <Card className="card-login rounded-4  border-0">
            <Card.Body className="p-md-5">
              <Alert variant="danger d-none" ref={alertComponents}>
                ERROR
              </Alert>
              <div>
                <h4>Sign-in</h4>
              </div>
              <Form className="mt-4" onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="p-3 rounded-4 focus-ring focus-ring-danger border-dark border-opacity-25"
                    name="username"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className="p-3 rounded-4 focus-ring focus-ring-danger border-dark border-opacity-25"
                    name="password"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-3 p-3 rounded-4 bg-danger border-danger"
                >
                  Login
                </Button>

                <ListGroup className="mt-4 rounded-4">
                  <ListGroup.Item>
                    <a
                      href=""
                      className=" text-decoration-none"
                      onClick={() => handleRedirect("/forget-password")}
                    >
                      Forget Password?
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <a
                      href=""
                      className=" text-decoration-none"
                      onClick={() => handleRedirect("/register")}
                    >
                      Don't Have Acoount? Register Now
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default Logins;
