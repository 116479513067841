import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Logins from "./pages/logins";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import React from "react";
import Dashboard from "./pages/Dashboard";
import PersonalList from "./pages/PersonalList";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Logins />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/list" element={<PersonalList />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
