import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import Navbars from "../components/Navbars";
import CardPersonal from "../components/CardPersonal";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { instance } from "../global variable/instance";

const PersonalList = () => {
  const navigate = useNavigate(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    tokenValidate();
    getData();
  }, []);

  const getData = () => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/garap/`
      )
      .then((ress) => {
        setData(ress.data.data);
      })
      .catch(() => {
        navigate("/login");
      });
  };

  const tokenValidate = () => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/user/permision`
      )
      .catch(() => {
        navigate("/login");
      });
  };

  const handleDelete = (id) => {
    instance
      .post(
        `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/private/api/garap/delete`,
        {
          id_ga: id,
        }
      )
      .then((ress) => {
        handleShow();
        getData();
      })
      .catch((err) => {
        navigate("/login");
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Navbars />
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Deleted Success !</Modal.Title>
          </Modal.Header>
          <Modal.Body>Successfuly deleted Wishlist</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <section className="py-5">
        <Container>
          <Row className="g-4">
            {data.map((item, index) => {
              return (
                <Col md="4" key={index + 1}>
                  <CardPersonal
                    img={item?.img_url}
                    name={item?.name_aidrop}
                    action={item?.task}
                    twitter={item?.twitter}
                    site={item?.url_aidrop}
                    detail_task={item?.refrence_task}
                    onClick={() => handleDelete(item?.id_ga)}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PersonalList;
