import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  ListGroup,
  Toast,
} from "react-bootstrap";
import imageBig from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ForgetPassword = () => {
  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(true);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);
  return (
    <>
      <section className="py-5">
        <div className="position-fixed px-3 d-flex justify-content-end  w-100">
          <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">HTP Aidrop</strong>
              <small>Now</small>
            </Toast.Header>
            <Toast.Body>Oh-no, forget password is not available now</Toast.Body>
          </Toast>
        </div>
        <Container className="d-flex flex-column align-items-center">
          <div>
            <h2 className="mb-5 d-flex justify-content-center align-items-center gap-2">
              Rei Aidrop
              <Image src={imageBig} className="img-logo" />
            </h2>
          </div>
          <Card className="card-login rounded-4  border-0">
            <Card.Body className="p-md-5">
              <div>
                <h4>Forget Password</h4>
              </div>
              <Form className="mt-4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="p-3 rounded-4 focus-ring focus-ring-danger border-dark border-opacity-25 disabled"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-3 p-3 rounded-4 bg-danger border-danger disabled"
                >
                  Next
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default ForgetPassword;
