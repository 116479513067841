import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  ListGroup,
  Alert,
} from "react-bootstrap";
import imageBig from "../assets/logo.png";
import { instance } from "../global variable/instance";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate(null);
  const alertComponents = useRef(null);
  const handleRegister = (e) => {
    e.preventDefault();
    if (!e.target.username.value || !e.target.password.value) {
      return (
        (alertComponents.current.className = "fade alert alert-warning show"),
        (alertComponents.current.innerHTML = "Enter email and password")
      );
    } else {
      instance
        .post(
          `https://decd-2401-c080-1400-5c3b-5400-4ff-fea7-1d29.ngrok-free.app/api/user/create`,
          {
            username: e.target.username.value,
            password: e.target.password.value,
          }
        )
        .then((ress) => {
          alertComponents.current.className = "fade alert alert-success show";
          alertComponents.current.innerHTML = "Successfuly Created ";
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        })
        .catch((err) => {
          return (
            (alertComponents.current.className =
              "fade alert alert-warning show"),
            (alertComponents.current.innerHTML = err.response.data.errors)
          );
        });
    }
  };
  return (
    <>
      <section className="py-5">
        <Container className="d-flex flex-column align-items-center">
          <div>
            <h2 className="mb-5 d-flex justify-content-center align-items-center gap-2">
              HTP Aidrop
              <Image src={imageBig} className="img-logo" />
            </h2>
          </div>
          <Card className="card-login rounded-4  border-0">
            <Card.Body className="p-md-5">
              <Alert variant="danger d-none" ref={alertComponents}>
                ERROR
              </Alert>
              <div>
                <h4>Register</h4>
              </div>
              <Form className="mt-4" onSubmit={handleRegister}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="p-3 rounded-4 focus-ring focus-ring-danger border-dark border-opacity-25"
                    name="username"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className="p-3 rounded-4 focus-ring focus-ring-danger border-dark border-opacity-25"
                    name="password"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Satoshi Nakamoto"
                    className="p-3 rounded-4 focus-ring focus-ring-danger border-dark border-opacity-25"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-3 p-3 rounded-4 bg-danger border-danger"
                >
                  Register
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default Register;
